// Generated by grunt-webfont


@font-face {
	font-family:"icons";
	src:url("../../fonts/icons.eot");
	src:url("../../fonts/icons.eot?#iefix") format("embedded-opentype"),
		url("../../fonts/icons.woff2") format("woff2"),
		url("../../fonts/icons.woff") format("woff"),
		url("../../fonts/icons.ttf") format("truetype"),
		url("../../fonts/icons.svg#icons") format("svg");
	font-weight:normal;
	font-style:normal;
}

.icon {
	
		font-family:"icons";
	
	display:inline-block;
	vertical-align:middle;
	line-height:1;
	font-weight:normal;
	font-style:normal;
	speak:none;
	text-decoration:inherit;
	text-transform:none;
	text-rendering:auto;
	-webkit-font-smoothing:antialiased;
	-moz-osx-font-smoothing:grayscale;
}


// Icons


.icon_glyphicons-281-settings:before {
	content:"\f101";
}


.icon_glyphicons-52-eye-open:before {
	content:"\f102";
}


.icon_glyphicons-88-log-book:before {
	content:"\f103";
}


.icon_glyphicons-social-23-youtube:before {
	content:"\f104";
}


.icon_glyphicons-social-32-twitter:before {
	content:"\f105";
}


.icon_glyphicons-social-40-e-mail:before {
	content:"\f106";
}
