$h1-fontsize: 2.6rem !important;
$h2-fontsize: 1.8rem !important; 
$h3-fontsize: 1.5rem !important;
/* hier mit umgehen wir die fehlenden bullets bei materializecss */
.browser-default-uls {
  ul {
    padding-left: 30px!important;
    margin-top: 10px;
    margin-bottom: 15px;
    li {
      list-style-type: disc!important;
      ul {
        li {list-style-type: circle!important;}
      }
    }
  }
}

nav ul li {
    list-style-type: none;
}

.collection {
    list-style-type: none;
    border: none!important;
    background: none;
   }

.collection-item {
    background: none!important;
     }
